import BaseModel from "@/models/base-model.js";

export default class ClothCategory extends BaseModel {
  fields = {
    name: {
      type: "text",
      title: "Nama Kategori",
      label: "nama",
      minLen: 3,
      validationRules: {
        required: true,
        minLength: true,
      },
    },
    sku_code: {
      type: "text",
      title: "Kode SKU",
      label: "sku_code",
      minLen: 3,
      validationRules: {
        required: false,
        minLength: false,
      },
    },
    description: {
      type: "textarea",
      title: "Deskripsi",
      label: "description",
    },
  };
  endpoint = process.env.VUE_APP_API_BASE_URL + "cloth-categories";
}
